import * as React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import {SEO} from "../components/seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BlogSection from "../contents/Home/blog-section";
import FindIstSection from "../contents/find-ist-section";

const Find = ({data}) => {
    return (
        <div className={'find-ist-page'}>
            <Helmet>
                <title>Inter Protocol - {data.contentfulPage.title}</title>
            </Helmet>
            <Header/>

            <FindIstSection content={data.contentfulPage.sections[0]}/>

            <section className={"section blog-section"}>
                <div className={'container container-slim'}>
                    <BlogSection contentful={data.contentfulPage.sections[1]} blogPosts={data.allContentfulBlogPost.nodes}/>
                </div>
            </section>

            <div className={'section footer-section'}>
                <Footer/>
            </div>
        </div>
    )
}

export default Find

export const Head = ({data}) => (
    <SEO title={data.contentfulPage.title} description={data.contentfulPage.description && data.contentfulPage.description.description}/>
)

export const query = graphql`
    query findIstPage {
        contentfulPage(contentful_id: {eq: "OWcNyHoygGtwlJY7ed1IZ"}) {
            title
            slug
            description {
                description
            }
            metaTitle
            metaDescription {
                metaDescription
            }
            sections {
                ... on ContentfulFindIstSection {
                    title
                    elements {
                        title
                        slug
                        elements {
                            title
                            logo {
                                url
                                title
                            }
                            body {
                                raw
                            }
                            twitterUrl
                            telegramUrl
                            discordUrl
                            websiteUrl
                        }
                    }
                }
                ... on ContentfulBlogSection {
                    title
                }
            }
        }
        allContentfulBlogPost(limit: 3, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                id
                thumbnail {
                    url
                    title
                }
                excerpt
                title
                slug
                publishedAt(formatString: "MMMM DD, YYYY")
            }
        }
    }`;
