import React, {useState} from 'react';
import {renderRichText} from "gatsby-source-contentful/rich-text";
import ISTModule from "../components/ist-module";

const FindIstSection = ({content}) => {

    const [categoryIndex,setCategoryIndex] = useState(-1);
    const [open,setOpen] = useState(false);

    function changeCategory(index){
        setCategoryIndex(index);
        setOpen(!open);
    }

    return (
        <>
            <section className={"section find-ist-section"}>
                <div className={'container container-slim'}>
                    <h1 className={'uppercase'}>{content.title}</h1>
                    
                    <div className={'description hidden sm:block'}>
                        {content.description && renderRichText(content.description)}
                    </div>

                    <div className={`flex flex-wrap md:flex-nowrap tabs ${open ? 'opened' : ''}`}>
                        <div className={`basis-full md:basis-auto ${categoryIndex === -1 ? 'active' : ''} md:mr-4 lg:mr-7`}>
                            <a href="#all" onClick={()=>changeCategory(-1)}>All</a>
                        </div>
                        {content.elements &&
                            content.elements.map((category, index) => {
                                return (
                                    <div className={`basis-full md:basis-auto ${categoryIndex === index ? 'active' : ''}  md:mr-4 lg:mr-7`} key={index}>
                                        <a href={`#${category.slug}`} onClick={()=> changeCategory(index)}>{category.title}</a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section className={"section tab-container"}>
                <div className={'container container-slim'}>
                    <div className={'flex flex-wrap'}>
                        <div className={`basis-full ${categoryIndex === -1 ? 'block' : 'hidden'}`}>
                            <div className={'grid gap-5 sm:grid-cols-2 lg:grid-cols-3'}>
                            {content.elements && content.elements.map((category, i) => {
                                return category.elements.map((element, j) => {
                                    return (
                                        <ISTModule element={element} category={category} i={i} j={j}/>
                                        )
                                })
                            })}
                            </div>
                        </div>

                    {content.elements && content.elements.map((category, i) => {
                            return <div className={`basis-full ${i === categoryIndex ? 'block' : 'hidden'}`}>
                                <div className={'grid gap-5 sm:grid-cols-2 lg:grid-cols-3'}>
                                    {category.elements.map((element, j) => {
                                        return(
                                            <ISTModule element={element} category={category} i={i} j={j}/>
                                        )
                                    })}
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
            </section>
        </>
    )
}

export default FindIstSection;
