import React from "react";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import twitterIcon from "../images/twitter-icon.svg";
import discordIcon from "../images/discord-icon.svg";
import telegramIcon from "../images/telegram-icon.svg";
import websiteIcon from "../images/website-icon.svg";

const ISTModule = ({element,category, i, j}) => {
    return (
        <div className={''}>
                <div className={'element'} key={'element' + i + j}>
                    <div className={'flex items-center'}>
                        <div className={'basis-1/5'}>
                            <img src={element.logo.url} alt={element.logo.title} />
                        </div>
                        <div className={'basis-4/5 pl-7 flex-col'}>
                            <div className={'title flex-1'}>{element.title}</div>
                            <div className={'subtitle'}>{category.title}</div>
                        </div>
                    </div>
                    {element.body && <div className={'text'}>

                        {element.body && <div className={'mb-4 md:mb-10'}>{renderRichText(element.body)}</div>}

                        <div className={'socials'}>
                            <div className={'flex items-center'}>
                                {element.twitterUrl && <div className={'basis-auto pr-5'}>
                                    <a href={element.twitterUrl} target={'_blank'} rel={'noreferrer'}>
                                        <img src={twitterIcon} alt="Twitter"/>
                                    </a>
                                </div>}
                                {element.discordUrl && <div className={'basis-auto pr-5'}>
                                    <a href={element.discordUrl} target={'_blank'} rel={'noreferrer'}>
                                        <img src={discordIcon} alt="Discord"/>
                                    </a>
                                </div>}
                                {element.telegramUrl && <div className={'basis-auto pr-5'}>
                                    <a href={element.telegramUrl} target={'_blank'} rel={'noreferrer'}>
                                        <img src={telegramIcon} alt="Telegram"/>
                                    </a>
                                </div>}
                                {element.websiteUrl && <div className={'basis-auto pr-5'}>
                                    <a href={element.websiteUrl} target={'_blank'} rel={'noreferrer'}>
                                        <img src={websiteIcon} alt="Website"/>
                                    </a>
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
        </div>
    );
}

export default ISTModule;
